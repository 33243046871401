import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  const [data, setData] = useState<any[]>([]);
  const [newPost, setNewPost] = useState(
    Object.freeze({
      title: "",
      content: "",
      username: "",
      postedAt: new Date().toLocaleString(),
    })
  );

  const handleChange = (e: { target: { name: any; value: string } }) => {
    console.log(`${[e.target.name]}: ${e.target.value.trim()}`);
    setNewPost({
      ...newPost,
      postedAt: new Date().toLocaleString(),
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    console.log(newPost);
    console.log("POST", JSON.stringify(newPost));

    fetch("https://posts-worker.richardccota-test.workers.dev/posts", {
      method: "POST",
      body: JSON.stringify(newPost),
      headers: { "content-type": "application/json" },
    })
      .then((response) => {
        console.log("resp", response);
        return response;
      })
      .then((data) => {
        console.log("d", data);
        fetchData();
      })
      .catch((e) => console.log("ERR", e));
  };

  const fetchData = () => {
    fetch("https://posts-worker.richardccota-test.workers.dev/posts")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {data
          ? data.map((post) => (
              <div key={post.title}>
                <p>{post.title}</p>
                <p>{post.content}</p>
                <p>Posted by: {post.username}</p>
                <p>Posted at: {post.postedAt}</p>
              </div>
            ))
          : null}
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <div>
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input type="text" name="username" onChange={handleChange} />
            </label>
            <label>
              Title:
              <input type="text" name="title" onChange={handleChange} />
            </label>
            <label>
              Content:
              <input type="text" name="content" onChange={handleChange} />
            </label>
            <input type="submit" value="Submit" onChange={handleChange} />
          </form>
        </div>
      </header>
    </div>
  );
}

export default App;
